export default function SimpleMenu(selector) {
    var self = null;
    var elem = $(selector);
    var selected = null;
    var items = null;
    var currentUrl = null;
    var listener = null;

    var MENU_WRAPPER_CLASS = "menu_items_wrapper";
    var MENU_ITEM_CLASS = "menu_item";
    var SELECTED_CLASS = "selected";
    var SELECTED_MENU_ITEM_SELECTOR = "." + MENU_ITEM_CLASS + "." + SELECTED_CLASS ;

    this.getElement = function() {
        return elem;
    }

    this.getItems = function() {
        return items;
    }

    this.getSelected = function() {
        var ss = items.find(SELECTED_MENU_ITEM_SELECTOR);

        if (ss.length > 0)
            return ss.first();
        else
            return null;
    }

    this.removeSelected = function() {
        var selected = self.getSelected();

        if (selected != null)
            selected.removeClass(SELECTED_CLASS);
    }

    this.findByUrl = function(url) {
        var found = null;

        items.find("." + MENU_ITEM_CLASS).each(function() {
            var item = $(this);

            if (item.data('url') == url)
                found = item;
        });

        return found;
    }

    this.find = function(selector) {
        return items.find(selector);
    }

    this.selectByUrl = function(url) {
        var element = self.findByUrl(url);

        if (element != null) {
            self.setSelected(element);
            currentUrl = url;
        }

        return self.getSelected() != null;
    }

    this.setListener = function(l) {
        listener = l;
    }

    this.setSelected = function(element) {
        self.removeSelected();

        element.addClass(SELECTED_CLASS);

        if (listener != null && typeof(listener.onItemSelected) != 'undefined')
            listener.onItemSelected(element, self);

        return self.getSelected() != null;
    }

    var __construct = function (that) {
        self = that;

        items = elem.find("." + MENU_WRAPPER_CLASS);

        items.find("." + MENU_ITEM_CLASS).click(function() {
            var item = $(this);
            var url = item.data('url');

            if (item.hasClass('disabled'))
                return;

            if (typeof(url) == 'undefined') {
                if (toolbar != null && typeof(toolbar.collapse) != "undefined")
                    toolbar.collapse();
            } else {
                var shouldOpenNewWindow = item.data("opens-new-window") == 1;

                if (shouldOpenNewWindow) {
                    window.open(url, '_blank');
                } else {
                    goForwardSafely(url);
                }
            }

            self.setSelected(item);
        });

        currentUrl = window.location.pathname;

        self.selectByUrl(currentUrl);
    }(this)
}