export default function scriptLoader(scripts, callback) {
    var count = scripts.length;

    function urlCallback(url) {
        return function () {
            console.log(url + ' was loaded (' + --count + ' more scripts remaining).');
            if (count < 1) {
                callback();
            }
        };
    }

    function loadScript(url) {
        var s = document.createElement('script');
        s.setAttribute('src', url);
        s.onload = urlCallback(url);
        document.head.appendChild(s);
    }

    for (var k = 0; k < scripts.length; k++) {
        loadScript(scripts[k]);
    }
};

$(document).ready(function() {
    resizeFullElements();
});

$(window).resize(function() {
    resizeFullElements();
});

Array.gen = function(value, len) {
    var arr = [];

    for (var i = 0; i < len; i++) {
        arr.push(value);
    }

    return arr;
};

export function toastAlert(message, success) {
    $.toast({
        loader: false,
        bgColor: success ? "#2199e8" : "#F44336",
        text: message,
        textColor: "#fff",
        position: 'bottom-right'
    });
}

export function countChar(val, target, limit) {
    var len = val.value.length;
    if (len >= limit) {
        val.value = val.value.substring(0, limit);
    } else {
        $(target).text( len + "/" + limit);
    }
}

export function getExtendedMonth(date, abv) {
    var month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][date.getMonth()];

    if (!checkIfEmpty(abv) && abv)
        month = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"][date.getMonth()];

    return month;
}

export function getExtendedWeekday(date) {
    return [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado"
    ][date.getDay()];
}

export function extendedDate(date, isAbbreviatedMonth) {
    var d = new Date(date);

    if (typeof(date) == "string") {
        var s = date.split("/");
        d = new Date(s[2], s[1] - 1, s[0]);
    }

    var iam = !checkIfEmpty(isAbbreviatedMonth);

    return "{weekday}, {day} de {month} de {year}".format({
        weekday: getExtendedWeekday(d),
        month: getExtendedMonth(d, iam),
        year: d.getFullYear(),
        day: d.getDate()
    });
}

export function brazilianDate(date) {
    var d = new Date(date);

    if (typeof(date) == "string") {
        var s = date.split("/");
        d = new Date(s[2], s[1] - 1, s[0]);
    }

    return d;
}

export function parseAddressInLines(data) {
    if (checkIfEmpty(data))
        return "";

    var text = parseAddressAsHtml(data);

    return text.split("<br/>");
}

export function parseAddressAsHtml(data) {
    if (checkIfEmpty(data))
        return "";

    var text = "";
    var DIVIDER = ", ";
    var O_DIVIDER = " - ";
    var LINE_BREAK = "<br/>";

    var hasAddress = !checkIfEmpty(data.address);
    var hasNumber = !checkIfEmpty(data.number);
    var hasCep = !checkIfEmpty(data.cep);
    var hasCity = !checkIfEmpty(data.city);
    var hasState = !checkIfEmpty(data.state);
    var hasComplement = !checkIfEmpty(data.complement);

    if (hasAddress)
        text += data.address;

    if (hasNumber) {
        if (hasAddress)
            text += DIVIDER;

        text += data.number;
    }

    if (hasAddress && hasNumber)
        text += LINE_BREAK;

    if (hasComplement) {
        text += data.complement;
    }

    if (hasCity) {
        if (hasComplement)
            text += LINE_BREAK;

        text += data.city;
    }

    if (hasState) {
        if (hasCity)
            text += DIVIDER;

        text += data.state;
    }

    if (hasCep) {
        text += (hasCity || hasState) ? O_DIVIDER : LINE_BREAK;

        text += data.cep;
    }

    return text;
}

/* FIXME: cortar ref direta */
export function requireSignIn(params) {
    var shouldWarn = params.shouldWarn == true;
    var warning = checkIfEmpty(params.warning) ? '' : params.warning;
    var redirectUrl = checkIfEmpty(params.redirectUrl) ? window.location.href : params.redirectUrl;
    var type = checkIfEmpty(params.type) ? null : params.type;

    $("#sign_in_reveal .holder").attr('data-should-warn-user', shouldWarn);
    $("#sign_in_reveal .holder").attr('data-warning', warning);
    $("#sign_in_reveal .holder").data('redirect-url', redirectUrl);

    if (type != null)
        $("#sign_up_reveal .holder form.sign_up_form input[name='user[role_type]']").val(type);

    $("#sign_in_reveal").foundation('open');
}

window.Clipboard = (function(window, document, navigator) {
    var textArea,
        copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
    }

    function selectText() {
        var range,
            selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyToClipboard() {
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    copy = function(text) {
        createTextArea(text);
        selectText();
        copyToClipboard();
    };

    return {
        copy: copy
    };
})(window, document, navigator);