export default function PollingManager(callback, data, period) {
    let DEFAULT_PERIOD = 5000;

    var self = null;
    var period = period;
    var callback = callback;
    var intervalId = null;
    var listeners = [];

    this.data = data;

    this.addListener = function(listener) {
        if (!hasListener(listener))
            listeners.push(listener);
    };

    this.removeListener = function(listener) {
        if (hasListener(listener))
            listeners.removeItem(listener);
    };

    var hasListener = function(listener) {
        var found = false;

        listeners.forEach(function(l) {
            if (l === listener)
                found = true;
        });

        return found;
    };

    this.setCallback = function(c) {
        self.stop();

        callback = c;
    };

    this.isMonitoring = function() {
        return intervalId != null;
    };

    this.start = function() {
        if (intervalId != null)
            self.reset();

        intervalId = setInterval(function() {
            self.execute(self.data);
        }, period);

        listeners.forEach(function(l) {
            if (!!l.didStart)
                l.didStart(self, self.data);
        });
    };

    this.stop = function() {
        self.reset();

        listeners.forEach(function(l) {
            if (!!l.didStop)
                l.didStop(self, self.data);
        });
    };

    this.reset = function() {
        if (intervalId)
            clearInterval(intervalId);

        intervalId = null;
    };

    this.execute = function(data) {
        if (!!callback)
            callback(self, data);

        listeners.forEach(function(l) {
            if (!!l.didExecute)
                l.didExecute(self, data);
        });
    };

    var __construct = function (that) {
        self = that;

        if (checkIfEmpty(period))
            period = DEFAULT_PERIOD;

        if (checkIfEmpty(callback))
            callback = function() {};

        if (checkIfEmpty(data))
            data = {};

        self.reset();
    }(this)
}