console.group("inicializando o pack de imagens do app")



import '/var/www/apps/jaf/app/frontend/images/brand/app_logo_250.png';

import '/var/www/apps/jaf/app/frontend/images/brand/app_logo_400.png';

import '/var/www/apps/jaf/app/frontend/images/brand/app_logo_full.png';

import '/var/www/apps/jaf/app/frontend/images/brand/app_logo_full_original.png';

import '/var/www/apps/jaf/app/frontend/images/brand/app_metadata.jpg';

import '/var/www/apps/jaf/app/frontend/images/brand/app_symbol.png';

import '/var/www/apps/jaf/app/frontend/images/brand/app_symbol_250.png';

import '/var/www/apps/jaf/app/frontend/images/brand/app_symbol_400.png';

import '/var/www/apps/jaf/app/frontend/images/brand/app_text.png';

import '/var/www/apps/jaf/app/frontend/images/brand/app_text_150.png';

import '/var/www/apps/jaf/app/frontend/images/brand/app_text_250.png';

import '/var/www/apps/jaf/app/frontend/images/brand/app_text_400.png';

import '/var/www/apps/jaf/app/frontend/images/empty_image_large.png';

import '/var/www/apps/jaf/app/frontend/images/pub/background.jpg';

import '/var/www/apps/jaf/app/frontend/images/pub/header_bkg.jpg';

import '/var/www/apps/jaf/app/frontend/images/site/152-01.svg';

import '/var/www/apps/jaf/app/frontend/images/site/background.jpg';

import '/var/www/apps/jaf/app/frontend/images/site/bkg_pt.jpg';

import '/var/www/apps/jaf/app/frontend/images/site/bkg_pt2.jpg';

import '/var/www/apps/jaf/app/frontend/images/site/bkg_pt3.jpg';

import '/var/www/apps/jaf/app/frontend/images/site/bkg_pt4.jpg';

import '/var/www/apps/jaf/app/frontend/images/site/bkg_pt5.jpg';

import '/var/www/apps/jaf/app/frontend/images/site/bkg_pt6.jpg';

import '/var/www/apps/jaf/app/frontend/images/site/ferro_dir.png';

import '/var/www/apps/jaf/app/frontend/images/site/ferro_esq.png';

import '/var/www/apps/jaf/app/frontend/images/site/institutional.jpg';

import '/var/www/apps/jaf/app/frontend/images/site/institutional_bkg.jpg';

import '/var/www/apps/jaf/app/frontend/images/site/institutional_mobile.png';

import '/var/www/apps/jaf/app/frontend/images/site/map_marker.png';


console.groupEnd();
