console.group("inicializando o pack js do app")

/* FIXME: Até fazer a portabilidade completa dos js para compilar em ES6 */
import PollingManager from '../js/polling_helper.js';
import SimpleMenu from '../js/simple_menu.js';
import M from '../js/msg_helper.js.erb';


import {
    default as ContextToolbar,
    Toolbar,
    SearchBar
} from '../js/toolbars.js';

import {
    default as scriptLoader,
    toastAlert,
    countChar,
    getExtendedMonth,
    getExtendedWeekday,
    extendedDate,
    brazilianDate,
    parseAddressInLines,
    parseAddressAsHtml,
    requireSignIn
} from "../js/helpers.js";


global.M = M

global.PollingManager = PollingManager
global.SimpleMenu = SimpleMenu
global.ContextToolbar = ContextToolbar
global.Toolbar = Toolbar
global.SearchBar = SearchBar
global.scriptLoader = scriptLoader
global.toastAlert = toastAlert
global.countChar = countChar
global.getExtendedMonth = getExtendedMonth
global.getExtendedWeekday = getExtendedWeekday
global.extendedDate = extendedDate
global.brazilianDate = brazilianDate
global.parseAddressInLines = parseAddressInLines
global.parseAddressAsHtml = parseAddressAsHtml
global.requireSignIn = requireSignIn

console.groupEnd()


console.group("inicializando componentes js do app")

console.groupEnd()